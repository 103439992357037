import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';

import '../../Assets/Pages/campagneSdr.css';

import { fetchApi } from '../../Network/NetworkService';
import toIsoDate from '../../Services/toIsoDate';
import { notif } from '../Ui/Notification';
import { rqState } from '../../Constants';
import { Modal } from 'react-bootstrap';

const typeStates = [
    { value: '', label: 'Tous' },
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

const userOpt = [
    { value: 'new', label: 'Nouveau' },
    { value: 'recurrent', label: 'Récurrent' },
]

const CampagneSdr = () => {
    const [option, setOption] =  useState('');
    const [mailOption, setmailOption] =  useState('');
    const [reqState, setReqState] = useState('waiting');
    const [reqType, setReqType] = useState('');
    const [dateRange, setDateRange] = useState([new Date('2022-01-01').toISOString(), new Date('2022-02-01').toISOString()])
    const [doctorList, setDoctorList] = useState([])
    const [userList, setUserList] = useState([])

    const [doctorSelected, setDoctorSelected] = useState(null);
    const [userType, setUserType] = useState('new');
    const [value, setValue] = useState({
        email: '',
        phone: '',
        subj: '',
        msg: ''
    });
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [sendProgress, setSendProgress] = useState(0)


    const reset = () => {
        setValue({
            email: '',
            phone: '',
            msg: '',
            subj: ''
        })
        setDoctorSelected([]);
        setmailOption('')
    }

    const getDoctors = () => {
        fetchApi(`/campagne-sdr/doctorList`)
        .then((res) => {
            setDoctorList(res.doctors)
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setLoading(false);
        });
    }

    const getUsers = () => {
        setUserLoading(true)
        fetchApi(`/campagne-sdr/userList`, {
            method: 'POST',
            body: JSON.stringify({
                date: dateRange,
                doctorSelected,
            })
        })
        .then((res) => {
            setUserList(res.users)
            setUserLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setUserLoading(false);
        });
    }

    useEffect(() => {
        getDoctors()
    }, [])

    const memoDoctorSelected = useMemo(() => doctorSelected, [doctorSelected])
    const memoDateRange = useMemo(() => dateRange, [dateRange])

    useEffect(() => {                
        if ((option === 'relance' && !Array.isArray(memoDoctorSelected ) && !!memoDateRange)) {
            getUsers()
        } else {
            setUserList([])
        }
        
    }, [memoDoctorSelected, memoDateRange, option])

    useEffect(() => {
        if (sendProgress == 100) {
            setLoading(false)
            setSendProgress(0)
            notif.addNotification(
                'Success',
                'Message envoyé !',
                'success'
            )
        }
    }, [sendProgress])

    const sendRelance = async () => {
        setLoading(true)
        for (const [index, user] of userList.entries()) {
            try {
                await fetchApi(`/campagne-sdr/sendMessage/relance`, {
                    method: 'POST',
                    body: JSON.stringify({
                        user,
                        doctorSelected,
                        ...value
                    })
                })

                setSendProgress(Math.round(((index + 1) / userList.length) * 100))
            } catch (e) {
                console.log(e);
                setSendProgress(Math.round(((index + 1) / userList.length) * 100))
            }
            
        }

    }

    const send = () => {
        setLoading(true);
        fetchApi(`/campagne-sdr/sendMessage/${option}${mailOption ? `/${mailOption}` : ''}`, {
            method: 'POST',
            body: JSON.stringify({
                ...value,
                ...(mailOption === 'perRequest' ? { state: reqState, date: toIsoDate(date, 0, 1), type: reqType } : {}),
                ...(mailOption === 'perUser' ? { userType, date: toIsoDate(date + '-01', 0, 1) } : {}),
            })
        })
        .then(() => {
            notif.addNotification(
                'Success',
                'Message envoyé !',
                'success'
            )
            setLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setLoading(false);
        });
    }

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }                

    return (
        <div className='campagne-sdr'>
            <p>Campagne SDR</p>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <span>Envoyer un :</span>
                <select onChange={(e) => {
                    setOption(e.target.value);
                    reset();
                }}>
                    <option value="">choisir</option>
                    <option value="email">email</option>
                    <option value="sms">sms</option>
                    <option value="relance">relance</option>
                </select>
            </div>
            {option === 'email' ? (
                <>
                    <div className='sdr-block'>
                        <select onChange={(e) => {
                            setmailOption(e.target.value);
                        }}>
                            <option value="email">par email</option>
                            <option value="perRequest">par requete</option>
                            <option value="perUser">par type d'user</option>
                        </select>
                        {mailOption === 'perRequest' && (
                            <>
                                <select onChange={(e) => {
                                    setReqState(e.target.value);
                                }}>

                                    {rqState.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <select onChange={(e) => {
                                    setReqType(e.target.value);
                                }}>

                                    {typeStates.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Debut : <input value={date} max={new Date().toISOString().split('T')[0]} onChange={(e) => setDate(e.target.value)} type="date" name="date" />
                                </div>
                            </>
                        )}
                        {mailOption === 'perUser' && (
                            <>
                                <select onChange={(e) => {
                                    setUserType(e.target.value);
                                }}>

                                    {userOpt.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Mois de : <input  
                                        max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                        min="2021-01"
                                        name="month" 
                                        onChange={(e) => setDate(e.target.value)}
                                        type="month"
                                        value={date}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {mailOption === 'email' && (
                        <input value={value.email} onChange={handleChange} name='email' type="text" className='input-search' placeholder='Email' />
                    )}
                    <input value={value.subj} onChange={handleChange} name='subj' type="text" className='input-search' placeholder='Sujet' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={(mailOption === 'email' && !value.email) || !value.subj || !value.msg || loading}>Envoyer</button>
                </>
            ) : option === 'sms' ? (
                <>
                    <input value={value.phone} onChange={handleChange} name='phone' type="text" className='input-search' placeholder='Téléphone' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={!value.phone || !value.msg || loading}>Envoyer</button>
                </>
            ) : option === 'relance' && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Modal
                        show={userLoading}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        className="modal-spinner"
                    >
                        <span className="loader" />
                        {userLoading && <p>Récupération des utilisateurs...</p>}
                    </Modal>
                    <p>Relance des non recurrents</p>
                    <div>
                        Date : <DateRangePicker locale="fr-FR" onChange={value => setDateRange(value)} value={dateRange} maxDate={new Date()}/>
                    </div>
                    <div style={{ width: 400 }}>
                        <Select
                            value={doctorSelected}
                            isClearable
                            onChange={(v) => setDoctorSelected(v)}
                            options={doctorList.map((v) => ({ value: v._id, label: `${v.FName} ${v.LName}`}))}
                            isMulti={false}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black',
                            })}}
                            role="checkbox"
                            placeholder="Selection de docteur" 
                        />
                    </div>
                    {doctorSelected.value ? <p>Nombre d'user : {userList.length}</p> : null}
                    <textarea value={value.subj} onChange={handleChange} name='subj' type="text" className='input-search' placeholder='Contenu' />
                    {loading ? <p style={{ textAlign: 'center'}}>Envoie en cours : {sendProgress} %</p> : <button onClick={sendRelance} disabled={!doctorSelected || !dateRange || !value.subj || loading}>Envoyer</button>}
                </div>
            )}
        </div>
    )
}

export default CampagneSdr;